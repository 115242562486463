import * as T from './types'

const DeleteXCircle = (props: T.ISVGProps) => (
  <svg
    width={props.width}
    height={props.height}
    stroke={props.stroke}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9.54999"
      r="9"
      fill={props.fill}
    />
    <path
      d="M12.9631 6.71204L12.2016 5.95056L9.18272 8.96946L6.16382 5.95056L5.40234 6.71204L8.42125 9.73094L5.40234 12.7498L6.16382 13.5113L9.18272 10.4924L12.2016 13.5113L12.9631 12.7498L9.9442 9.73094L12.9631 6.71204Z"
      fill="white"
    />
  </svg>
)

export default DeleteXCircle
