import { useContext, useEffect, useRef, useState } from 'react'

import { EventHandlerContext } from 'services/contexts'

const noop = () => {}

interface IOpts<Data> {
  // we don't always want to enable useDebounce since nested debounced values will cause a double delay
  enabled?: boolean

  // sometimes we want a function to be called like _.debounce.  The current use-case is to not trigger
  // TextInput's onChange for its initial value, only its debounced value.
  fn?: (data: Data) => void
}

export default function useDebounce<Data>(value: Data, delay: number, opts: IOpts<Data> = {}) {
  const handler = useRef<NodeJS.Timeout>()
  const { current: id } = useRef<object>({})
  const prevValue = useRef<Data>()
  const { fn = noop } = opts
  const enabled = opts.enabled || opts.enabled === undefined
  const [debouncedValue, setDebouncedValue] = useState<Data | null>(value)
  const { debouncingRefs, addDebounceRef, deleteDebounceRef } = useContext(EventHandlerContext)

  useEffect(() => {
    if (!enabled || value === prevValue.current) return

    prevValue.current = value

    if (!debouncingRefs.has(id)) {
      addDebounceRef(id)
    } else {
      clearTimeout(handler.current as NodeJS.Timeout)
    }

    handler.current = setTimeout(() => {
      deleteDebounceRef(id)
      setDebouncedValue(value)
      fn(value)
    }, delay)
  }, [value, delay, enabled])

  return debouncedValue
}
