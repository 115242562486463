import {
  FIRST_DISJOINT,
  SCALE_FACTOR,
  SECOND_DISJOINT,
  STEPS_PER_INTERVAL,
  THIRD_DISJOINT,
  tickMarks,
  tickMarksMobile,
} from './constants'

// const roundToFiveThousands = (value: number) => Math.round(value / 5000) * 5000
const roundToTenThousands = (value: number) => Math.round(value / 10000) * 10000
const roundToTwentyFiveThousands = (value: number) => Math.round(value / 25000) * 25000

export const interpolate = (value: number) => {
  if (value <= FIRST_DISJOINT) return value * 5000 + tickMarks[0]

  if (value <= SECOND_DISJOINT) return (value - STEPS_PER_INTERVAL) * 10000 + tickMarks[1]

  if (value <= THIRD_DISJOINT) return (value - STEPS_PER_INTERVAL * 3) * 17500 + tickMarks[3]

  return (value - STEPS_PER_INTERVAL * 4) * 25000 + tickMarks[4]
}

export const reverseInterpolate = (price: number) => {
  if (price <= 250000) return Math.round((price - tickMarks[0]) / 5000)

  if (price <= 900000) return (price - tickMarks[1]) / 10000 + STEPS_PER_INTERVAL

  if (price <= 1375000) return (price - tickMarks[3]) / 17500 + STEPS_PER_INTERVAL * 3

  return (price - tickMarks[4]) / 25000 + STEPS_PER_INTERVAL * 4
}

export const interpolateMobile = (
  value: number,
  windowWidth: number,
  scaleFactor: number,
  theme?: any,
) => {
  // safety check
  if (windowWidth >= theme?.metrics?.tablet) return interpolate(value)

  /*
   * we need to interpolate mobile values to work be interpolated again by our interpolate function
   * example:
   *
   *               236 scrollLeft                    `unknown`
   *                -----------                X    -----------
   *   total mobile width less window width          120 steps
   *
   * (NB: it's minus window width bc we add 50vw padding to each side of mobile slider to create
   * a vestibule and keep everything aligned, 50vw * 2 = window width)
   *
   */
  const totalSteps = STEPS_PER_INTERVAL * (tickMarks.length - 1)

  const proportionalValue
    = (value * totalSteps) / (tickMarks.length * (scaleFactor || SCALE_FACTOR) - windowWidth)

  const interpolatedValue = interpolate(proportionalValue)

  // if (proportionalValue <= FIRST_DISJOINT) return roundToFiveThousands(interpolatedValue)
  if (proportionalValue <= SECOND_DISJOINT) return roundToTenThousands(interpolatedValue)

  return roundToTwentyFiveThousands(interpolatedValue)
}

export const getScrollLeftFromValue = (
  value: number | undefined,
  scaleFactor: number,
  windowWidth?: number,
) => {
  if (!value || !windowWidth) return 0

  const scrollWidth = tickMarksMobile.length * scaleFactor - windowWidth
  const minimumValue = tickMarksMobile[0]
  const maximumValue = tickMarksMobile[tickMarksMobile.length - 1] - minimumValue
  const proportionalValue = (value - minimumValue) / maximumValue
  return proportionalValue * scrollWidth
}
