import { getLatLng } from 'react-places-autocomplete'

import * as T from 'types'

/* eslint-disable camelcase */

const normalizeAddressComponents = (address_components: google.maps.GeocoderAddressComponent[]) => {
  const addressComponents: Partial<T.ICreateListingData> = {}
  address_components.forEach(component => {
    if (component.types) {
      component.types.forEach(type => {
        if (type === 'administrative_area_level_1') {
          return (addressComponents[type] = component.short_name)
        }

        if (type !== 'political') addressComponents[type] = component.long_name
      })
    }
  })
  return addressComponents
}

const extractLocation = async (place: google.maps.GeocoderResult) => {
  if (!place || !place.address_components) return {}

  const addressComponents = normalizeAddressComponents(place.address_components)
  const latLng = await getLatLng(place)
  addressComponents.latitude = latLng.lat
  addressComponents.longitude = latLng.lng
  return addressComponents
}

const formatLocation = async (
  place: google.maps.GeocoderResult,
): Promise<Partial<T.ICreateListingData>> => {
  const addressComponents = await extractLocation(place)
  if (!addressComponents || !Object.keys(addressComponents).length) return {}

  const street = addressComponents.street_number
    ? `${addressComponents.street_number} ${addressComponents.route}`
    : String(addressComponents.route || '')

  return {
    city: String(
      addressComponents.locality
        || addressComponents.administrative_area_level_3
        || addressComponents.sublocality_level_1
        || addressComponents.neighborhood
        || '',
    ),
    country: String(addressComponents.country || ''),
    county: String(addressComponents.administrative_area_level_2 || ''),
    latitude: addressComponents.latitude as number,
    longitude: addressComponents.longitude as number,
    street,
    state: String(addressComponents.administrative_area_level_1 || ''),
    zip: String(addressComponents.postal_code || ''),
  }
}

export default formatLocation
