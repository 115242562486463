/* interpolation factors */
const FIRST_DISJOINT = 30
const SECOND_DISJOINT = 90
const THIRD_DISJOINT = 120
const STEPS_PER_INTERVAL = 30

const SCALE_FACTOR = 220 // pixels between tick on mobile

/* tick marks setup */
const tickMarks = [100000, 250000, 550000, 850000, 1375000, 2125000]

/* this is partially supported, but some things might break if you have different number of ticks */
const tickMarksMobile = tickMarks

export {
  FIRST_DISJOINT,
  SECOND_DISJOINT,
  THIRD_DISJOINT,
  STEPS_PER_INTERVAL,
  SCALE_FACTOR,
  tickMarks,
  tickMarksMobile,
}
