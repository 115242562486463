import { FunctionComponent } from 'react'
import styled from 'styled-components'

import Body1 from 'design-system/Typography/Body/Body1'

import Spacer from 'components/Spacer'

interface IContainerProps {
  backgroundColor?: string
}
const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 29px 16px;
  background-color: ${props => props.backgroundColor};
  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    row-gap: 24px;
    padding: 43px 60px;
  }
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    flex-direction: row;
    padding: 47px;
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  row-gap: 16px;

  @media (min-width: ${props => props.theme.metrics.tablet}px) {
    flex-direction: row;
  }
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  width: auto;
  height: 20px;
  @media (min-width: ${props => props.theme.metrics.desktop}px) {
    height: 28px;
  }
`

interface IProps {
  backgroundColor?: string
}
const FeaturedOnMediaA: FunctionComponent<IProps> = ({ backgroundColor }) => (
  <Container backgroundColor={backgroundColor}>
    <Body1 color="neutral300">As seen in</Body1>
    <Spacer
      size={20}
      hideTablet
      desktopSize={30}
      desktopLargeSize={30}
    />
    <LogoContainer>
      <FlexRow>
        <Image
          src={`${process.env.CDN_BASE_URL}/q_auto,w_234/amazon/assets/logos-homepage/photo-images/forbes-logo-4.png`}
          width="118"
          height="20"
          alt="forbes logo"
        />
        <Spacer
          size={24}
          tabletSize={30}
          desktopSize={30}
          desktopLargeSize={40}
        />
        <Image
          src={`${process.env.CDN_BASE_URL}/q_auto,w_294/amazon/assets/logos-homepage/photo-images/usa-today-logo-4.png`}
          width="147"
          height="20"
          alt="usa-today logo"
        />
        <Spacer
          size={24}
          tabletSize={30}
          desktopSize={30}
          desktopLargeSize={40}
        />
        <Image
          src={`${process.env.CDN_BASE_URL}/q_auto,w_208/amazon/assets/logos-homepage/photo-images/fortune-logo-4.png`}
          width="104"
          height="20"
          alt="fortune logo"
        />
      </FlexRow>
      <Spacer
        size={30}
        desktopLargeSize={40}
        hideMobile
      />
      <FlexRow>
        <Image
          src={`${process.env.CDN_BASE_URL}/q_auto,w_372/amazon/assets/logos-homepage/photo-images/tech-crunch-logo-4.png`}
          width="136"
          height="20"
          alt="tech crunch logo"
        />
        <Spacer
          size={24}
          tabletSize={30}
          desktopSize={30}
          desktopLargeSize={40}
        />
        <Image
          src={`${process.env.CDN_BASE_URL}/q_auto,w_268/amazon/assets/logos-homepage/photo-images/authority-magazine-logo-4.png`}
          width="134"
          height="20"
          alt="authority magazine logo"
        />
      </FlexRow>
    </LogoContainer>
  </Container>
)

export default FeaturedOnMediaA
