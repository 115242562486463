import { api } from './api'

export const validateZip = async (zip: string) => {
  try {
    const response = await api.validateZip(zip)
    if (!response.ok || !response.data) throw new Error(`bad response: ${response.problem}`)

    if (response.data.length) return true

    return false
  } catch (error) {
    // a 404 means the zip is invalid
    return false
  }
}
