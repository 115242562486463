declare global {
  interface Window {
    chrome: any
    opr: any
  }
}

export const isChrome = () => {
  if (typeof window === 'undefined') return

  const isChromium = window.chrome
  const winNav = window.navigator
  const vendorName = winNav.vendor
  const isOpera = typeof window.opr !== 'undefined'
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1
  const isIOSChrome = winNav.userAgent.match('CriOS')

  // is Google Chrome on IOS
  if (isIOSChrome) return true

  // is Google Chrome
  if (
    isChromium !== null
    && typeof isChromium !== 'undefined'
    && vendorName === 'Google Inc.'
    && isOpera === false
    && isIEedge === false
  ) {
    return true
  }

  // not Google Chrome
  return false
}
